
import { Link } from 'react-router-dom';

import ShareButtons from './ShareButtons';
import TagsAndDate from './TagsAndDate';
import { Desktop } from '../modules/useResponsive';
import RouteFor from '../modules/RouteFor';
import { Div } from './Elements';
import _ from '../modules/_';

import './Tile.scss';

export default function EpisodeCard({ episode, style }) {

  var image = _.get(episode, 'imageMedium') || _.get(episode, 'show.image') || '/images/fallback.jpg';
  var className = `Tile episode ${Desktop() ? "Desktop" : "Mobile"}`;
  style = {
    ...{ backgroundImage:`url(${image})` },
    ...(style || {})
  };

  return (<Link className={className} style={style} to={RouteFor({ episode })}>
    
    <div className='gradient'>
      <Div style={{marginTop:'34px'}} 
        size={"20"} 
        lineclamp={1} 
        className="bold" >
          {episode.title}
        </Div>
      {TagsAndDate(episode, episode.formattedDate)}
    </div>
  </Link>
  );
}