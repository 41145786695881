
import { StyledLink } from "./Elements"
import RouteFor from "../modules/RouteFor"

export default function ColumnLink({ to, className, name, style, onClick, children, flex, d, m }) {

  return <StyledLink
    className={'size28 upper bold' + (className ? " " + className : "")}
    to={to}
    flex={flex}
    d={{
      paddingTop: '60px',
      paddingBottom: '72px',
      borderWidth: 0,
      ...d,
      ...style
    }}
    m={{
      paddingTop: '6px',
      paddingBottom: '6px',
      width: '100%',
      ...m,
      ...style
    }}
    onClick={onClick}
    html={children ? children : name}
  >
    
  </StyledLink>
}