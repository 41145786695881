

const link = {
  article: (article) => `/read/discorder/${article.slug}`,
  issue: (issue) => `/read/issue/${issue.slug}`,
  episode: (episode) => `/listen/${episode.showSlug}/${episode.id}`,
  show: (show) => `/listen/${show.slug}`,
  column: (column) => `/read/column/${column.slug}`,
  genre: (genre) => `/genres/${genre.slug}`

}

const isValid = {
  article: (article) => article.slug,
  issue: (issue) => issue.slug,
  episode: (episode) => episode.id && episode.showSlug,
  show: (show) => show.slug,
  column: (column) => column.slug,
  genre: (genre) => genre.slug

}

export default function RouteFor(props) {

  var itemType = Object.keys(props)[0];//.key
//  console.log("route for props", props);
  if (isValid[itemType](props[itemType])) {
    return link[itemType](props[itemType]);
  } else {
    return "#";
  }

}