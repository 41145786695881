import { Div, Header } from "./Elements";
import { useLoaderData } from "react-router-dom";
export default function StaticPage({ path }) {

  var content = useLoaderData();
  return (
    <Div d={{
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }}>
      <Header style={{ textTransform: 'capitalize' }}>{path.replace('-', ' ')}</Header>
      <Div html={content}>

      </Div>
    </Div>
  )
}