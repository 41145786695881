
import { Link, useLoaderData } from "react-router-dom"
import _ from 'lodash'
import Data from "../modules/Data";
import Grid from "../components/Grid";
import TagsAndDate from "../components/TagsAndDate";
import Icon from "../components/Icon";
import ShowCard from "../components/ShowCard";
import { Desktop, DesktopClass } from "../modules/useResponsive";
import './Listen.scss';
import { Header, Div } from "../components/Elements";
import { DateTime } from "luxon";
import { CustomDurationFormat, CustomDateFormat } from "../modules/CustomDateTimeFormats";
import RouteFor from "../modules/RouteFor";
import ShareButtons from "../components/ShareButtons";

function ShowPageEpisodeCard({episode}){

  var image = _.get(episode, 'imageMedium') || _.get(episode, 'show.image') || '/images/fallback.jpg';


  return (
    <Link 
      className={ `Tile episode ${Desktop() ? "Desktop" : "Mobile"}`} 
      style={{ backgroundImage: `url(${image})` }} 
      to={RouteFor({ episode })}>
      <div className='gradient'>
        <></>
        <Div style={{marginTop:'34px'}} size={"20"} lineclamp={1} className="bold" >{episode.title}</Div>
      {TagsAndDate(episode, episode.formattedDate)}
      </div>
    </Link>
  );
}

export default function ListenShow() {

  var { show, schedule, errors } = useLoaderData();

  var showImage = _.get(show, 'imageLarge') || _.get(show, 'imageMedium', '/images/fallback.jpg');

  var nextEntry = _.first(_.get(_.first(schedule), 'schedule'));
  if (nextEntry && nextEntry.start && nextEntry.end) {

//    console.log("entry", nextEntry.start);
    var start = DateTime.fromISO(nextEntry.start);//.toLocaleString(DateTime.TIME_SIMPLE);
    var end = DateTime.fromISO(nextEntry.end);//.toLocaleString(DateTime.TIME_SIMPLE);

//    console.log('from ', start, "to ", end);

    var dayOfWeek = start.toFormat('EEEE');

    show.formattedTime = `${dayOfWeek}s ${CustomDurationFormat(nextEntry.start, nextEntry.end)}`;
    show.nextShowFormattedTime = CustomDateFormat(start);
  } else if (show) {
    show.formattedTime = '';
  }
  if (_.size(errors) > 0) {
    return <Div>
      <h1>Errors</h1>
      <pre>{JSON.stringify(errors, null, 2)}</pre>
      <Link to="/listen">Back to Listen</Link>
    </Div>
  }

  return <>

  <div className="ListenShow full-bleed" style={{marginTop:0}}>
      <div className='meets-edge' style={{ zIndex: 0, position: 'relative' }}>

        <div style={{
          position: 'fixed',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${showImage || '/images/fallback.jpg'})`
        }} />
      </div>
      <div className="showGradient meets-edge" style={{
        display: 'flex',
        color: 'white',
        justifyContent: 'center',
        position: 'relative',

      }}>
        <div className={DesktopClass("centre-column")} >
            <div className={`Tile show detail ${Desktop() ? "Desktop" : "Mobile"}`} >
              
              <div className="gradient">
                <Div
                  size={"28"}
                  className="bold"
                  html={show.title}
                ></Div>
                <Div className="subtitle">
                    {show.host ? (
                      <div>
                        Hosted by <b>{show.host}</b>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Div>{show.formattedTime}</Div>
                  </Div>
                  <Div className="description" html={show.description}>
                    {" "}
                  </Div>
                  <Div
                    className="socials-and-button"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <ShareButtons route={RouteFor({ show: show })} />
                  </Div>
                
              </div>
            </div>
        </div>
      </div>
      <div className="ShowPageContainer meets-edge" >

        <div className={DesktopClass("centre-column")} style={{
          marginBottom: Desktop() ? '72px' : '78px'
        }} >
          




    {show.nextShowFormattedTime && <>
      <h3
        className="top-border white"
        style={{ marginBottom: Desktop() ? '24px' : '12px' }}>
        Next Show
      </h3>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: Desktop() ? '7px' : '4px',
        fontSize: Desktop() ? '20px' : '13px'
      }}>
        <Icon Calendar ></Icon>
        &nbsp;{show.nextShowFormattedTime}
      </div>
    </>
    }
    <div className={DesktopClass("NextEpisode")}
      style={{ display: 'flex', alignItems: 'center', gap: Desktop() ? '32px' : '8px' }}>
      <img src={show.image || '/images/fallback.jpg'} alt={show.title} />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: Desktop() ? '16px' : '13px',
        lineHeight: '19.2px'
      }}>
        <Header style={{ marginBottom: '20px', marginTop: '0px' }} html={show.title}></Header>
        {TagsAndDate(show, null, true)}
      </div>
    </div>
    <h3
      className="top-border white"
      style={{ marginTop: '36px' }}>Past Aired</h3>
    <Grid
      childElementFn={(episode) => {

        //          episode.show = show;
        //          episode.title = `${show.title}`;
        return <ShowPageEpisodeCard episode={episode} key={episode.id} />;
      }}
      initialData={show.episodes}
      loadFn={Data.shows.episodes(show.id)}
      pageSize={4} buttonClass='light' addLinesBetween={Desktop() ? false : true} />




        </div>
      </div>

    </div>
  
  </>;
}


// inner
