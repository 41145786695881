import React from "react";
import { useEffect } from "react";
import "./HorizontalScroller.scss";
import { useKeenSlider } from "keen-slider/react";
import ParseStyleProps from "../modules/ParseStyleProps";
import "keen-slider/keen-slider.min.css";
import { Desktop } from "../modules/useResponsive";

export default function HorizontalScroller({ children, style, d, m }) {
  const spacing = 16;
  const leftBufferWidth = Desktop() ? 50 : 16;
  const [sliderRef, sliderInstance] = useKeenSlider({
    slides: {
      perView: "auto",
      spacing
    }
  });

  useEffect(() => {
      sliderInstance.current.update();
  }, [children]);

  //  sliderRef.slides = children;

  var className = "HorizontalScroller meets-edge";

  var props = ParseStyleProps({
    d,
    m,
    flex: "row"
  });


  return (
    <div className={className} style={style}>
      <div
        ref={sliderRef}
        style={{
          marginTop: "21px",
          flexWrap: "nowrap",
          //          overflowX: "scroll",
          //         paddingLeft: "16px",
          //        paddingRight: "16px",
          ...props.style
        }}
      >
        {[
          <div
            className="keen-slider__slide"
            key="bogus key"
            style={{
              border: "0.1px solid white",
              width: `${leftBufferWidth - spacing}px`
            }}
          ></div>,
          ...children
        ]}
      </div>
    </div>
  );
}
