const issue = {
  id: 149,
  name: "Issue #149 - June 2021",
  number: 149,
  date: "June 2021",
  image: "https://picsum.photos/637/854", // "https://via.placeholder.com/313x419",
  description:
    "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt fox. Bright vixens jump; dozy fowl quack.",
  link: "",
};

export default issue;
