import _ from "lodash";
import { DateTime } from "luxon";
import CachedRequest from "./CachedRequest.mjs";

var base = "/djland/api2/public/";

var genres = require("../test/fixtures/subgenres.json");

var DjlandApi = {
  genres: (offset, size) => {
    return Promise.resolve(
      genres.map(({ id, subgenre }) => {
        return { name: subgenre, slug: "" + id };
      })
    );
    //  .slice(0, size)
  },
};

export default DjlandApi;
