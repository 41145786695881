
import React, { useState } from "react";

import Icon from "./Icon";
import { Div } from "./Elements";

import DebouncingHover from "../modules/DebouncingHover";

import { isNumber, isNaN } from "lodash";

function LoadingIndicator() {
  return <Div
    flex="row center center" style={
      {
        height: '1.5em',
        width: '1.5em'
      }
    }><Icon HourglassHalf></Icon></Div>
}

function PlayPause({ small, pause, play, isPlaying }) {
  var element;
  var onClick;
  if (small) { //desktop
    element = isPlaying ? <Icon PauseSmall></Icon>
      : <Icon PlaySmall ></Icon>
    onClick = () => {
      isPlaying ? pause() : play();
    }
  } else {
    element = isPlaying ? <Icon Pause onClick={pause} />
      : <Icon Play onClick={play} />
  }

  return <Div
    style={{
      height: '1.5em',
      width: '1.5em',
      cursor: 'pointer'
    }}
    flex='row center center'
    onClick={onClick}>
    {element}
  </Div>
}


function PlayerScrubber({ timeInfo, onScrub, onScrubEnd }) {
  return (
    <input
      className='scrubber'
      type="range"
      id="seek-slider"
      max={timeInfo.end}
      value={timeInfo.current}
      onChange={e => onScrub(e.target.value)}
      onMouseUp={onScrubEnd}
      onKeyUp={onScrubEnd}
      style={{
        '--min': 0,
        '--max': timeInfo.end,
        '--val': timeInfo.current
      }}
    />
  );
}

function VolumeSlider({ volume, setVolume }) {

  var [isVisible, setIsVisible] = useState(false);
  var [volumeBeforeMute, setVolumeBeforeMute] = useState(null);

  function onChangeSlider(e) {
    var v = e.target.value;
    setVolume(v);
  }

  function toggleMute() {
    if (1 * volume < 0.01) {
      if (isNumber(volumeBeforeMute) && !isNaN(volumeBeforeMute)) {
        setVolume(volumeBeforeMute);
      }
    } else {
      setVolumeBeforeMute(1 * volume);
      setVolume(0);
    }
  }

  function VolumeIcon() {

    var icon;

    if (volume < 0.01) {
      icon = <Icon VolumeMuted />
    } else if (volume < 0.5) {
      icon = <Icon VolumeLow />
    } else {
      icon = <Icon VolumeHigh />
    }

    return <Div
      onClick={toggleMute}
      style={{
        cursor: 'pointer',
        minWidth: '27.5px'
      }}>
      {icon}
    </Div>
  }
  return (
    <Div d={{ width: '25px' }} >

      <DebouncingHover
        onEnterFn={() => setIsVisible(true)}
        onLeaveFn={() => setIsVisible(false)}
        leaveTimeout={900}
        id="vol">
        <Div style={{
          height: '98px',
          width: '32px',
          borderRadius: '5px',
          background: 'white',
          display: isVisible ? 'block' : 'none',
          position: 'fixed',
          bottom: '50px',
          right: '54px'
        }} /* m={{
          width: '42px',
          height: '200px',
          bottom: '50px',
          right: '57px'
        }} */
        >

          <input
            className='volume-slider'
            type="range"
            onChange={onChangeSlider}
            max="1"
            min="0"
            step="0.01"
            value={volume}
            style={{
              transform: 'rotate(-90deg)',
              transformOrigin: '50px 45px',
              cursor: 'pointer',
              width: '90px'
            }}
          />
        </Div>

        {VolumeIcon()}
      </DebouncingHover>

    </Div>
  );
}
export {
  PlayerScrubber,
  PlayPause,
  VolumeSlider,
  LoadingIndicator
}
