import TagsAndDate from "./TagsAndDate";
import { Desktop } from "../modules/useResponsive";
import RouteFor from "../modules/RouteFor";
import { Div, StyledLink } from "./Elements";

import "./Tile.scss";

export default function ArticleCard({
  article,
  detail,
  tall,
  style,
  className = ""
}) {
  return (
    <>
      <StyledLink
        className={
          className +
          " Tile article " +
          (tall ? "tall " : "") +
          (Desktop() ? "Desktop" : "Mobile")
        }
        to={RouteFor({ article: article })}
        m={{
          marginTop: 0,
          borderBottom: "2px solid var(--accent)",
          borderBottomWidth: tall ? "0" : "2px",
          width: tall ? "83.3%" : "var(--mobileWidth)",
          paddingBottom: tall ? "49.5px" : "36px"
        }}
        style={style}
      >
        <div
          className="image"
          style={{
            backgroundImage: `url(${(tall ? article.imageLarge : article.image) || '/images/fallback.jpg'})`
          }}
        />
        <Div d={{ marginBottom: "20px" }}>
          <Div
            size={tall ? "28/20" : "20"}
            className="bold"
            d={{ marginTop: tall ? "11px" : "8px" }}
            m={{ marginTop: "8px" }}
            lineclamp={tall ? 2 : 1}
            
          >{article.title}</Div>
          {detail & article.description ? (
            <div>{article.description}</div>
          ) : (
            <></>
          )}
          <Div
            flex="row"
            d={{ marginTop: tall ? "34px" : "24px" }}
            m={{ marginTop: "12px" }}
          >
            {TagsAndDate(article)}
          </Div>
        </Div>
      </StyledLink>
    </>
  );
}
