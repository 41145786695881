

import { Div } from "./Elements";

var year = (new Date()).getFullYear();

export default function CopyrightStatement({ size, style }) {
  return (<>
    <Div size={size} style={style} >
      All content published and broadcasted is produced by The Student Radio Society of UBC and protected by copyright.
      <p />
      © CiTR & Discorder {year}
    </Div>
  </>
  );
}