
var offset = getComputedStyle(document.body).getPropertyValue('--bar-height').replace('px', '') * 1 + 32;

var ScrollBehaviour = {
  scrollToId: (id) => {
    var top = document.getElementById(id).offsetTop - offset;
    window.scroll({ top, behavior: 'smooth' })
  }
}

export default ScrollBehaviour;