module.exports = [
  {
    id: 0,
    name: "Event Title",
    start: "2023-07-28T19:00:00.000-08:00",
    end: "2023-07-28T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 1,
    name: "Event Title",
    start: "2023-07-18T19:00:00.000-08:00",
    end: "2023-07-18T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 2,
    name: "Event Title",
    start: "2023-07-08T19:00:00.000-08:00",
    end: "2023-07-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 10,
    name: "Event Title",
    start: "2023-07-04T19:00:00.000-08:00",
    end: "2023-07-28T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 11,
    name: "Event Title",
    start: "2023-07-03T19:00:00.000-08:00",
    end: "2023-07-18T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 12,
    name: "Event Title",
    start: "2023-07-02T19:00:00.000-08:00",
    end: "2023-07-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 13,
    name: "Event Title",
    start: "2023-07-01T19:00:00.000-08:00",
    end: "2023-07-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 14,
    name: "Event Title",
    start: "2023-06-02T19:00:00.000-08:00",
    end: "2023-07-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },

  {
    id: 300,
    name: "Event Title",
    start: "2023-03-28T19:00:00.000-08:00",
    end: "2023-03-28T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 400,
    name: "Event Title",
    start: "2023-03-18T19:00:00.000-08:00",
    end: "2023-03-18T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 500,
    name: "Event Title",
    start: "2023-03-08T19:00:00.000-08:00",
    end: "2023-03-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 600,
    name: "Event Title",
    start: "2023-02-28T19:00:00.000-08:00",
    end: "2023-02-28T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 700,
    name: "Event Title",
    start: "2023-02-18T19:00:00.000-08:00",
    end: "2023-02-18T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  },
  {
    id: 800,
    name: "Event Title",
    start: "2023-02-08T19:00:00.000-08:00",
    end: "2023-02-08T23:00:00.000-08:00",
    venueName: "Victory Square",
    venueAddress: "200 W Hastings St, Vancouver, BC"
  }
];
