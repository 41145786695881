
var get = (key, fallback) => {
  try {
    var value = localStorage.getItem(key);
    if (value === null) {
      return fallback;
    } else {
      return value;
    }
  } catch (error) {
    return fallback;
  }
}
var set = (key, string) => {
  try {
    localStorage.setItem(key, string);
  } catch (error) {

  }
}

export { get, set };