import _ from "lodash";

import "./Landing.scss";
import "./routes/Read.scss";
import Data from "./modules/Data";
import Grid from "./components/Grid";
import IssueCard from "./components/IssueCard";
import ArticleCard from "./components/ArticleCard";
import { Div, StyledLink, Button, Header } from "./components/Elements";
import TagsAndDate from "./components/TagsAndDate";
import WhatWereReading from "./components/WhatWereReading";
import Icon from "./components/Icon";
import { Desktop, DesktopClass } from "./modules/useResponsive";
import RouteFor from "./modules/RouteFor";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import issue from './mockData/issue';

export default function Landing() {
  //  const state = [{ key: null, content: null }];
  const state = {
    frontPageArticle: {},
    latestIssue: {},
    issues: [{}]
  };
  const [content, setContent] = useState(state);

  useEffect(() => {
    Promise.all([
      Data.articles.frontPage(),
      Data.issues.latest(),
      Data.issues.list(0, 2)
    ]).then(([frontPageArticle, latestIssue, issues]) => {
      var c = {
        frontPageArticle,
        latestIssue,
        issues: issues
      };
      //        console.log(c);
      setContent(c);
    });
  }, []);
  var frontPageImage = _.get(content, "frontPageArticle.imageLarge") || _.get(content, "frontPageArticle.imageMedium", "/images/fallback.jpg");

  return (
    <div className={DesktopClass("Landing")}>
      <div className={DesktopClass("FrontPageArticle")}>
        <Link to={RouteFor({ article: content.frontPageArticle })}>
          <div
            className="image"
            style={{
              backgroundImage: `url(${frontPageImage})`
            }}
          />
        </Link>
        <Div className="FrontPageArticleText" flex="column flex-end">
          {Desktop() ? <h3>The Front Page</h3> : null}
          <Link to={RouteFor({ article: content.frontPageArticle })}>
            <Div
              m={{ class: "size28 bold", marginTop: "9.5px" }}
              d={{ class: "size48-tall bold", marginTop: "0" }}
            >
              {content.frontPageArticle.title}
            </Div>
          </Link>
          <Div
            m={{ class: "size13", marginTop: "11px" }}
            d={{ class: "size20", marginTop: "58px" }}
            html={content.frontPageArticle.description}
          ></Div>
          <Div
            m={{ class: "size13", marginTop: "17px" }}
            d={{ class: "size16", marginTop: "24px" }}
            flex="row"
          >
            {TagsAndDate(content.frontPageArticle)}
          </Div>
          <Div d={{ marginTop: "72px" }}></Div>
        </Div>
      </div>
      <WhatWereReading />
      <Div
        className={DesktopClass("Magazine meets-edge")}
        d={{
          backgroundImage: `url(${content.latestIssue.image || '/images/fallback.jpg'})`,
          backgroundPosition: "center"
        }}
      >
        <div className="DesktopGradientOverlay" />
        <h1>Magazine</h1>
        <Div
          flex
          className="landingPageFlex"
          m={{ marginTop: "22px", display: "block" }}
        >
          <img
            className="cover latestIssueImage"
            src={content.latestIssue.imageLarge}
            style={{ maxWidth: "100%" }}
            alt={content.latestIssue.name}
          />
          <Div
            flex="column flex-end"
            d={{ marginLeft: "16px", marginRight: "16px" }}
          >
            <Header className={"bold size20"} m={{ marginTop: "24px" }}>
              Latest Issue
            </Header>
            <h4>{content.latestIssue.date}</h4>
            <Div
              d={{ class: "size20", marginTop: "34px" }}
              m={{ class: "size16", marginTop: "24px" }}
            >
              {content.latestIssue.description}
            </Div>
            <Button to={RouteFor({ issue: content.latestIssue })}>
              Check it Out
            </Button>
          </Div>
          <div />
          <div>
            <Div
              m={{ marginTop: "38px" }}
              className="past-issues-links"
              flex="row space-between baseline"
            >
              <Header className="upper bold" size="16/13">
                Past issues
              </Header>
              <StyledLink
                size="16/13"
                className="grey bold upper"
                to="/read/issues"
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "1px"
                }}
              >
                <span style={{ textAlign: "right" }}>View More</span>
                <Icon style={{ paddingLeft: "6px" }} ArrowRight />
              </StyledLink>
            </Div>
            <div className="past-issues">
              {content.issues.map((issue, i) => (
                <IssueCard issue={issue} key={i} />
              ))}
            </div>
          </div>
        </Div>
      </Div>
      <Div d={{ marginTop: "44px" }} m={{ marginTop: "71.67px" }}>
        <h1>Latest From Discorder</h1>

        <Grid
          d={{ gap: "16px" }}
          m={{ gap: "18px" }}
          buttonStyle={
            Desktop()
              ? {
                  marginTop: "54px",
                  marginBottom: "142px"
                }
              : {
                  marginTop: "0",
                  marginBottom: "56.8px"
                }
          }
          childElementFn={(x) => <ArticleCard article={x} key={x.id} />}
          loadFn={Data.articles.latest}
        ></Grid>
      </Div>
    </div>
  );
}
