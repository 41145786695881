

import _ from 'lodash';
import { DateTime } from "luxon"
import { Div, Header } from "../components/Elements"
import { DesktopClass, Desktop, Mobile } from "../modules/useResponsive";
import { useLoaderData } from 'react-router-dom';
import Album from '../components/Album';


function ListenCharts() {

  var albums = useLoaderData();
//  console.log("albums in page",albums);
  return (
    <div>

      <Div className={DesktopClass("centre-column")}>

        <Header
          size="28/16"
          style={{
            fontWeight: 'bold',
          }}
          d={{
            textTransform: 'uppercase',
            marginTop: '34px'
          }}
          m={{
            marginTop: '22px'
          }}
        >
          {DateTime.now().set({ weekday: 0 }).toLocaleString(
            { month: 'short', day: '2-digit', year: 'numeric' }
          )}
        </Header>
        <Header
          size="48/28"
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }}
          d={{
            lineHeight: '43px'
          }}
          m={{
            lineHeight: '20.6px'
          }}
        >
          weekly top albums
        </Header>

        <Div style={{
          marginTop: '34px'
        }}
          d={{
            marginBottom: '144px'
          }}
          m={{
            marginBottom: '92px'
          }}>
          {albums.map((a, n) => {
            return <Album album={a} number={n + 1} style={{ borderBottom: '1.5px solid #F5F5F5', height: '74px' }} key={n} />
          })}
        </Div>
      </Div>

    </div>
  );

}


export default ListenCharts