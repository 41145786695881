
var ColumnWPTags = [
  {
    "id": 17,
    "name": "Art",
    "slug": "art"
  },
  {
    "id": 15950,
    "name": "discothrash",
    "slug": "discothrash"
  },
  {
    "id": 2206,
    "name": "Features",
    "slug": "features"
  },
  {
    "id": 600,
    "name": "Real Live Action",
    "slug": "real-live-action"
  },
  {
    "id": 614,
    "name": "Under Review",
    "slug": "under-review"
  }
];

var StaticData = {
  ColumnWPTags,
  supportUrl: "https://citr.ca/donate"
}

export default StaticData;