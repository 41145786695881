
import "./Footer.scss"
import { Desktop } from './modules/useResponsive'
import Logo from './Logo';
import { Link } from 'react-router-dom';

import CitrSocialLinks from "./components/CitrSocialLinks";
import CopyrightStatement from "./components/CopyrightStatement";
import StaticData from "./StaticData";

export default function Footer() {

  const links = {
    citr: [
      {
        name: "Listen",
        to: "/listen"
      },
      {
        name: "Genres",
        to: "/genres"
      },
      {
        name: "Schedule",
        to: "/schedule"
      },
      {
        name: "Top 50 Charts",
        to: "/charts"
      },
    ],
    discorder: [

      {
        name: "Read",
        to: "/read"
      },
      {
        name: "Past Issues",
        to: "/read/issues"
      },
      {
        name: "Get Discorder",
        to: "/get-discorder"
      },
    ],

    more: [

      {
        name: "Info",
        to: "/info"
      },
      {
        name: "Get Involved",
        to: "/get-involved"
      },
      {
        name: "Events + Announcements",
        to: "/events"
      },
      {
        name: "Shop",
        to: "/shop"
      },
      {
        name: "History",
        to: "/history"
      },
      {
        name: "News & Results",
        to: "/news"
      },
      {
        name: "Policy",
        to: "/policy"
      },
      {
        name: "Contact",
        to: "/contact"
      },]
  };


  return (
    <div className={"Footer " + (Desktop() ? "Desktop" : "")}>

      <div className={"Footer-Logos " + (Desktop() ? "Desktop" : "")}>

        <Logo large white />
        <CitrSocialLinks />
      </div>
      <div className={"Footer-Links " + (Desktop() ? "Desktop" : "")}>

        {["citr", "discorder", "more"].map(s => {
          return (
            <div key={s}>
              <h5>{s}</h5>
              {links[s].map(({ to, name }) => <Link to={to} key={name}>
                <li>{name}</li>
              </Link>
              )}
            </div>
          )
        })}
        <div >
          <Link to={StaticData.supportUrl}><h5>support</h5></Link>
        </div>
        <CopyrightStatement size="16" />
      </div>
    </div >
  )
}