
//import { useState, useEffect } from 'react';

import Data from "./Data";

var setPlayerVisibilityFn;
var setOnAirForListenPage;
var setOnAirForPlayer;

var pollInterval = 14 * 60 * 1000;
//var pollInterval = 5000;
var timer;

function updateOnAir() {
  console.log("updateOnAir()")
  Data.shows.getOnAirShows()
    .then(onAir => {
//      console.log("new on air info", onAir);
      if (setOnAirForListenPage) {
        setOnAirForListenPage(onAir);
      }
      if (setOnAirForPlayer) {
        setOnAirForPlayer(onAir);
      }
    })
}

if (!timer) {
  console.log("polling for on air shows every", pollInterval, "ms");
  timer = setInterval(updateOnAir, pollInterval)
}

var AudioModule = {
  play: (content) => {
    if (setPlayerVisibilityFn) {
      console.log('play', content);
      setPlayerVisibilityFn(content);
    } else {
      console.error('set player visiblity was not set');
    }
  },
  hide: () => {

    if (setPlayerVisibilityFn) {
      setPlayerVisibilityFn(false);
    } else {
      console.error('set player visiblity was not set');
    }
  },
  provideOnAirFnForPlayer: (fn) => {
    setOnAirForPlayer = fn;
  },
  updateOnAir,
  provideOnAirFnForListenPage: (fn) => {
    setOnAirForListenPage = fn;
  },
  provideVisibilityFnForPlayer: (fn) => {
    setPlayerVisibilityFn = fn;
//    setPlayerVisibilityFn([])
  },
  /*
    notifyNowPlaying: (nowPlaying) => {
      Data.shows.onAirToday().then(shows => {
        console.log('set on air today');
        setOnAirForListenPage([nowPlaying, ...shows]);
      })
    },*/
  //  foo: 'bar'
};

export default AudioModule;