
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Data from "../modules/Data";
import { Desktop, DesktopClass } from "../modules/useResponsive";
import _ from "../modules/_";

import SearchResult from "../components/SearchResult";
import './SearchResults.scss';
import { Button, Div } from "../components/Elements";

export default function SearchResults() {

  //  var [urlSearchParams, setUrlSearchParams] = useSearchParams();
  var [searchParams, setSearchParams] = useSearchParams();

  var q = searchParams.get('q');

  var [selectedCategory, setSelectedCategory] = useState((searchParams.get('active') || "articles"));

  var availableCategories = ["articles", "shows", "episodes"];

  var defaultResults = _.zipObject(availableCategories, [null, null, null]);
  var defaultPagesAvailable = _.zipObject(availableCategories, [1, 1, 1]);
  var defaultPagesLoaded = _.zipObject(availableCategories, [0, 0, 0]);

  var [results, setResults] = useState(defaultResults);
  var [pagesAvailable, setPagesAvailable] = useState(defaultPagesAvailable);
  var [pagesLoaded, setPagesLoaded] = useState(defaultPagesLoaded);

  var [fetching, setFetching] = useState({
    articles: false,
    shows: false,
    episodes: false
  })

  var [status, setStatus] = useState('');

  useEffect(() => {
    console.log("reset everything");
    setResults({
      articles: null,
      shows: null,
      episodes: null
    });
    setPagesAvailable({
      articles: 1,
      shows: 1,
      episodes: 1
    });
    setPagesLoaded({
      articles: 0,
      shows: 0,
      episodes: 0
    }
    );
  }, [q]);

  var loadOneMorePage = useCallback((category, pageNum) => {
    setFetching(f => {
      return _.extend({}, _.set(f, category, true));
    })
    if (!pageNum) {
      pageNum = 1;
    }
    //    var nextPage = pagesLoaded[category] + 1;
    if (_.isString(q) && !_.isEmpty(q)) {

      return Data.search(q, category, pageNum, 20)
        /*.then((searchResults) => {
          var ids = _.map(searchResults, )
        })*/
        .then((searchResults) => {
          setResults(r => {
            var existing = _.get(r, category, []);
            if (!_.isArray(existing)) {
              existing = [];
            }
            var newResults = _.extend({}, _.set(r, category, _.uniqBy([...existing, ...searchResults.results], 'id')));
            console.log('new results', newResults);
            return newResults;
          });
          setPagesAvailable(a => {
            var newPagesAvailable = _.extend({}, _.set(a, category, searchResults.pages));
            console.log('new pages avail', newPagesAvailable);
            return newPagesAvailable;
          });
          setPagesLoaded(l => {
            var npl = _.extend({}, _.set(l, category, pageNum));
            console.log('new pages loaded', npl);
            return npl;
          });
          setFetching(f => {
            return _.extend({}, _.set(f, category, false));
          });

        });
    } else {
      return Promise.resolve();
    }
  }, [q]);


  useEffect(() => {
    if (_.isString(q) && !_.isEmpty(q)) {
      if (!_.isEmpty(results[selectedCategory])) {
        setStatus(`results for "${q}"`);
      } else {
        setStatus('no results found ;(');
      }
    } else {
      setStatus('ready to search');
    }
  }, [q, results, selectedCategory]);

  useEffect(() => {
    if (!_.isArray(results[selectedCategory])) {
      setStatus(`searching ${selectedCategory} for ${q}...`);
      loadOneMorePage(selectedCategory);
    }
    //setSearchParams({
    // q,//earchParams,
    // active: selectedCategory
    //});

  }, [q, results, selectedCategory, loadOneMorePage, setSearchParams]);

  function CategoryResults({ category }) {
    var r = _.get(results, category, []);
    if (!r) {
      return null;
    } else {

      return < >
        {r.map((result, i) => {

          var resultElement = <SearchResult />;

          if (selectedCategory === "articles") {
            resultElement = <SearchResult article={result} key={`article-${result.id}`} />;
          } else if (selectedCategory === 'shows') {
            resultElement = <SearchResult show={result} key={`show-${result.id}`} />;
          } else if (selectedCategory === 'episodes') {
            resultElement = <SearchResult episode={result} key={`episode-${result.id}`} />;
          }

          if (i < (r.length - 1)) {
            return (<Div key={result.id}>{resultElement} < hr /></Div>);
          } else {
            return resultElement;
          }
        })}

      </>;
    }

  }


  function hasMorePages(cat) {
    return pagesAvailable[cat] > pagesLoaded[cat] && pagesLoaded[cat] !== 0;
  }

  function hasResults(cat) {
    return _.isArray(results[cat]);// > 0 || 
  }

  function numResultsLabel(cat) {
    if (hasResults(cat) && hasMorePages(cat)) {
      return `${results[cat].length}+`;
    } else if (hasResults(cat)) {
      return results[cat].length;
    } else {
      return "";
    }
  }

  return (
    <Div className={DesktopClass('SearchResults')} style={{ marginBottom: Desktop() ? '134px' : '91px' }}>
      <h3 style={{
        opacity: '0.4',
        textTransform: 'uppercase',
        fontWeight: 'normal',
        marginBottom: Desktop() ? '36px' : '18px'
      }}>{status}
      </h3>

      <Div style={{
        display: 'flex',
        gap: Desktop() ? '36px' : '17px',
        marginBottom: Desktop() ? '36px' : '34px'
      }}>
        {availableCategories.map(category =>
          <h1 className={selectedCategory === category ? 'selected' : ''}
            key={category}
            onClick={() => setSelectedCategory(category)}>
            {category} <span>
              {
                fetching[category] ? "..." : numResultsLabel(category)
              }
            </span>
          </h1>)}

      </Div>
      <Div className={DesktopClass('results')} style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {CategoryResults({ category: selectedCategory })}

        {hasMorePages(selectedCategory) ? (
          fetching[selectedCategory] ?
            <span style={{ marginLeft: 'auto', marginRight: 'auto' }}>loading...</span> :
            <Button style={{marginTop:'32px'}} onClick={
              () => {
                var nextPage = pagesLoaded[selectedCategory] + 1
                loadOneMorePage(selectedCategory, nextPage);
              }
            }>Load More</Button>
        ) : null}
      </Div>

    </Div>


  )
}