
import { Div, Header, StyledLink } from "../components/Elements";
import { useLoaderData } from "react-router-dom";
import { DateTime } from "luxon";
import  {CustomDurationFormat}  from "../modules/CustomDateTimeFormats";
import _ from "../modules/_";
import RouteFor from "../modules/RouteFor";

function dateFormatted(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE)
}

function ListenSchedule() {

  var timeZone = DateTime.local().offsetNameShort;//offsetNameLong;
  var { schedule } = useLoaderData();

  if (_.isArray(schedule) && _.isArray(_.get(_.first(schedule), 'schedule'))) {

    return (
      <Div style={{ background: 'black', color: 'white' }}>


        <Header size="48/20"
          d={{
            lineHeight: '72px',
            fontWeight: 'bold',
            marginTop: '29px'
          }}
          m={{
            lineHeight: '30px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginTop: '24px'
          }}
        >
          CiTR 101.9FM Radio Schedule
        </Header>
        <Div
          size="20"
          style={{
            fontWeight: 'bold',
            lineHeight: '30px'
          }}
          d={{
            position: 'absolute',
            right: '0px',
            top: 'calc(var(--bar-height) + 36px)',
            lineHeight: '30px'
          }}
          m={{
            color: '#707070'
          }}>
          *All times are in {timeZone}
        </Div>

        <Div d={{
          paddingTop: '29px',
          paddingBottom: '50px'
        }}
          m={{
            marginTop: '24px',
            marginBottom: '84px'
          }}>

          {schedule.map((day, i) => {
            return (
              <Div key={day.date}
                d={{
                  marginTop: i === 0 ? '29px' : '48px'
                }}
                m={{
                  marginTop: i === 0 ? '24px' : '42px'
                }
                }>
                <Header
                  className={'top-border'}
                  size="16"
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                  }}>{dateFormatted(day.date)}
                </Header>
                {
                  day.schedule.map((entry, j) => {
                    return (
                      <Div
                        flex='row start'
                        style={{ gap: '12px' }}
                        d={{
                          marginTop: j === 0 ? '24px' : '12px',
                          //   gap: '33px'
                        }}
                        m={{
                          marginTop: j === 0 ? '21px' : '10px',
                          //     gap: '28px'
                        }}
                        key={(entry.show + '-' + entry.start)}>
                        <Div style={{
                          display: 'inline'
                        }}
                          d={{
                            minWidth: '135px'
                          }}
                          m={{
                            minWidth: '135px'
                          }}
                        >
                          {CustomDurationFormat(entry.start, entry.end)}
                        </Div>
                        <StyledLink to={RouteFor({show:entry})}>
                          {entry.show}
                        </StyledLink>
                      </Div>
                    );
                  })
                }
              </Div>
            );
          })
          }
        </Div>
      </Div>
    );
  } else {
    return (<Header>Schedule could not be loaded. Please try again later.</Header>)
  }
}

export default ListenSchedule