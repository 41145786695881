const albums = [
  {
    artist: "The Wombats",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["local"],
  },

  {
    artist: "Tj Felix",
    title: "(I Am) The Land",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
    ],
    tags: ["local"],
  },

  {
    artist: "PRINT HEAD",
    title: "In Motion",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["cancon"],
  },

  {
    artist: "The Vanity Project",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: [],
  },

  {
    artist: "did you die",
    title: "Thirteen Moons",
    image: "https://picsum.photos/443",
    links: [{ name: "soundcloud", url: "https://www.soundcloud.com/example" }],
    tags: ["local"],
  },

  {
    artist: "The Wombats",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [{ name: "bandcamp", url: "https://www.bandcamp.com/example" }],
    tags: [],
  },

  {
    artist: "Tj Felix",
    title: "(I Am) The Land",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["local"],
  },

  {
    artist: "PRINT HEAD",
    title: "In Motion",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["cancon"],
  },

  {
    artist: "The Vanity Project",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: [],
  },

  {
    artist: "did you die",
    title: "Thirteen Moons",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["local"],
  },

  {
    artist: "The Wombats",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: [],
  },

  {
    artist: "Tj Felix",
    title: "(I Am) The Land",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["local"],
  },

  {
    artist: "PRINT HEAD",
    title: "In Motion",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["cancon"],
  },

  {
    artist: "The Vanity Project",
    title: "Fix Yourself, Not the World",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: [],
  },

  {
    artist: "did you die",
    title: "Thirteen Moons",
    image: "https://picsum.photos/443",
    links: [
      { name: "bandcamp", url: "https://www.bandcamp.com/example" },
      { name: "spotify", url: "https://www.spotify.com/example" },
      { name: "soundcloud", url: "https://www.soundcloud.com/example" },
    ],
    tags: ["local"],
  },
];

export default albums;
