import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";

import { Div } from "./components/Elements";
import CitrSocialLinks from "./components/CitrSocialLinks";
import CopyrightStatement from "./components/CopyrightStatement";
import "./Bar.scss";
import Logo from "./Logo";
import Icon from "./components/Icon";
import _ from "./modules/_";
import BarCommon from "./BarCommon";

function BarMobile() {
  const [menuOpen, setMenuOpen] = useState(false);

  const [LinkTree, setLinkTree] = useState([
    {
      key: "listen",
      to: "/listen",
      expanded: true,
      children: [
        { key: "listen", to: "/listen" },
        { key: "genres", to: "/genres" },
        { key: "schedule", to: "/schedule" },
        { key: "top 50 charts", to: "/charts" }
      ]
    },
    {
      key: "read",
      to: "/read",
      children: [
        { key: "read", to: "/read" },
        { key: "past issues", to: "/read/issues" },
        { key: "get discorder", to: "/get-discorder" }
      ]
    },
    { key: "info", to: "info" },
    { key: "events", to: "/events" },
    {
      key: "shop",
      to: "https://citr-radio-and-discorder-magazine.square.site/"
    }
  ]);

  function ToggleExpand(linkData, desktop) {
    setLinkTree((oldLinkTree) => {
      var i = oldLinkTree.indexOf(linkData);
      return oldLinkTree.map((link, index) => {
        if (index === i) {
          return { ...link, expanded: !linkData.expanded };
        } else if (desktop) {
          return { ...link, expanded: false };
        } else {
          return link;
        }
      });
    });
  }

  var barCommon = BarCommon({ ToggleExpand });

  useEffect(() => {
    setMenuOpen(false);
  }, [barCommon.location, barCommon.urlSearchParams]);

  function MakeLink(linkData, style = {}) {
    if (linkData.children) {
      return (
        <Div
          link={linkData}
          expands={linkData}
          className={"NavLink"}
          style={{
            opacity: linkData.expanded ? "1" : "0.3",
            transition: "opacity 0.3s ease",
            ...style
          }}
          key={linkData.key}
        >
          {linkData.key}
        </Div>
      );
    } else if (linkData.to) {
      return (
        <Link
          key={linkData.key}
          to={linkData.to}
          className={"NavLink "}
          style={style}
        >
          <div>{linkData.key}</div>
        </Link>
      );
    }
  }

  function AnyExpanded() {
    var is = LinkTree.find((l) => {
      return l.expanded;
    });
    return is;
  }

  function MobileDropDownLinks() {
    var style = {
      width: "100%",
      minHeight: "30px",
      display: "flex",
      alignItems: "left",
      flexDirection: "column",
      justifyContent: "center",
      margin: "4px 0px 0px 0px"
    };
    return (
      <Div
        flex="column left flex-start"
        style={{
          position: "absolute",
          height: "calc(100vh - var(--bar-height))",
          width: "100%",
          top: "var(--bar-height)",
          lineHeight: "24px",
          fontSize: "20px",
          paddingTop: "16px"
        }}
      >
        {LinkTree.map((linkData) => {
          if (linkData.children) {
            return (
              <div
                onClick={() => ToggleExpand(linkData)}
                className={"NavLink"}
                style={style}
                key={linkData.key}
              >
                <span className={AnyExpanded() ? "grey" : ""}>
                  {linkData.key}
                </span>
                {linkData.expanded ? (
                  linkData.children.map((l) =>
                    MakeLink(l, {
                      opacity: "1",
                      margin: "2px 24px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center"
                    })
                  )
                ) : (
                  <></>
                )}
              </div>
            );
          } else {
            return (
              <Link
                key={linkData.key}
                to={linkData.to}
                className={
                  "NavLink " + (AnyExpanded() && !linkData.black ? "grey" : "")
                }
                style={style}
              >
                <div>{linkData.key}</div>
              </Link>
            );
          }
        })}
        <Div
          style={{
            flexGrow: "2",
            marginRight: "32px"
          }}
          flex="column flex-end"
        >
          <CitrSocialLinks />
          <CopyrightStatement
            size="13"
            style={{ marginTop: "26px", marginBottom: "62px" }}
          />
        </Div>
      </Div>
    );
  }

  return (
    <header
      style={
        menuOpen
          ? {
              height: "100vh",
              paddingTop: "13px"
            }
          : {}
      }
    >
      <Div
        className={"Bar Mobile"}
        style={{
          height: "100%",
          width: "calc(100% - 32px)",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap",
          padding: "14px 0px 12px 0px"
        }}
      >
        <Logo />
        <barCommon.DisappearsWhenSearching>
          <Link className="NavLink" to="https://citr.ca/donate">
            <div>support</div>
          </Link>
        </barCommon.DisappearsWhenSearching>
        <barCommon.SearchInput />
        <Div
          style={{
            cursor: "pointer",
            borderLeft: barCommon.searching ? "" : "2px solid var(--accent)",
            ...(barCommon.searching
              ? {
                  minWidth: "29px",
                  justifyContent: "end"
                }
              : {})
          }}
          onClick={() => barCommon.setSearching(!barCommon.searching)}
        >
          {barCommon.searching ? <Icon Close /> : <Icon Search />}
        </Div>
        <barCommon.DisappearsWhenSearching>
          <div
            style={{
              cursor: "pointer",
              borderLeft: barCommon.searching ? "" : "2px solid var(--accent)",
              justifyContent: "end",
              minWidth: "30px"
            }}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <Icon Close /> : <Icon Menu />}
          </div>
        </barCommon.DisappearsWhenSearching>
        <MobileDropDownLinks />
      </Div>
    </header>
  );
}

export default BarMobile;
