import {
  find,
  get,
  set,
  unset,
  each,
  map,
  first,
  last,
  random,
  flatten,
  compact,
  pick,
  size,
  parseInt,
  filter,
  union,
  uniq,
  uniqBy,
  includes,
  mergeWith,
  isEmpty,
  isArray,
  isString,
  isObject,
  isNumber,
  isFunction,
  zipObject,
  values,
  extend,
  split
} from 'lodash';

var _ = {
  find,
  get,
  set,
  unset,
  each,
  map,
  first,
  last,
  random,
  flatten,
  compact,
  pick,
  size,
  parseInt,
  filter,
  union,
  uniq,
  uniqBy,
  includes,
  mergeWith,
  isEmpty,
  isArray,
  isString,
  isObject,
  isNumber,
  isFunction,
  zipObject,
  values,
  extend,
  split
};

export default _;