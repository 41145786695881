
import _ from "lodash";
import { Div, Header, Img, StyledLink } from "./components/Elements";
import Grid from "./components/Grid";
import Data from "./modules/Data";

function ReadPastIssues() {
  return (
    <div>
      <Header
        style={{
          fontWeight: "bold",
          textTransform: "uppercase"
        }}
        d={{
          fontSize: "28px",
          lineHeight: "43px"
        }}
      >
        Past issues
      </Header>
      <Grid
        pageSize={12}
        d={{
          rowGap: "48px",
          columnGap: "16px"
          //border: '1px solid green'
        }}
        m={{
          rowGap: "19px"
        }}
        childElementFn={(issue) => (
          <StyledLink
            to={issue.pdf}
            target="_blank"
            key={issue.id}
            d={{
              width: "calc(25% - 16.0001px)"
              //border: '1px solid blue'
            }}
          >
            <Div
              d={{
                padding: "12px"
              }}
              m={{
                padding: "8px"
              }}
              style={{
                aspectRatio: "1",
                background: "#F5F5F5"
              }}
              flex="column center center"
            >
              <Img src={issue.imageThumb || '/images/fallback.jpg'} style={{ height: "100%" }} />

            </Div>
            <Div

              size="20/16"
              style={{ marginTop: '8px' }}
            >{_.isNumber(issue.issue_number)? `Issue # ${issue.issue_number} — `:""} {issue.title}</Div>
          </StyledLink>
        )}
        loadFn={discorderIssueNumberFillingInLoadFn}
      ></Grid>
    </div>
  );
}

var issueMapByOffset = {};
var lastIssueNumber;

function discorderIssueNumberFillingInLoadFn(offset, pageSize) {

  if (_.isArray(_.get(issueMapByOffset, offset))) {
    return Promise.resolve(issueMapByOffset[offset]);
  } else {
    return Data.issues.list(offset, pageSize).then(issues => {
      issueMapByOffset[offset] = _.map(issues, (issue, i) => {

        if (_.isNumber(lastIssueNumber)) {
          if (!_.isNumber(issue.issue_number)) {
            issue.issue_number = lastIssueNumber - 1;
          }
        }
        lastIssueNumber = issue.issue_number;
        return issue;
      });
      return issueMapByOffset[offset];
    });
  }
}
export default ReadPastIssues;
