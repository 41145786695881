
import _ from 'lodash';

import MultiSectionPage from "../components/MultiSectionPage"
import { Header } from '../components/Elements';
import { Img } from '../components/Elements';
import { Desktop, Mobile } from '../modules/useResponsive';
import { Div } from '../components/Elements';
import Icon from '../components/Icon';

export default function GetInvolved() {

  var sections = [
    {
      name: "Radio",
      id: "radio",
      html: _.range(0, 100).map(() => "radio lorem ipsum for now").join('&nbsp;&nbsp;&nbsp;  ,   '),
      headerStyle: {
        d: {
          marginTop: '68px'
        },
        m: {
          marginTop: '21px'
        }
      },
      style: {
        d: {
          marginTop: '21px'
        },
        m: {
          marginTop: '21px'
        }
      }
    },
    {
      name: "Discorder Magazine",
      id: "disco",
      html: _.range(0, 100).map(() => "disco lorem ipsum for now").join(' &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;  '),
      headerStyle: {
        d: {
          marginTop: '68px'
        },
        m: {
          marginTop: '48px'
        }
      },
      style: {
        d: {
          marginTop: '21px'
        },
        m: {
          marginTop: '8px'
        }
      }
    },
    {
      name: "Music",
      id: "music",
      html: _.range(0, 100).map(() => "music lorem ipsum for now").join(' &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;  '),
      headerStyle: {
        d: {
          marginTop: '68px'
        },
        m: {
          marginTop: '48px'
        }
      },
      style: {
        d: {
          marginTop: '34px'
        },
        m: {
          marginTop: '13px'
        }
      }
    },
    {
      name: "General Info",
      id: 'general-info',
      html: _.range(0, 100).map(() => "general info lorem ipsum for now").join(' &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;  '),
      headerStyle: {
        d: {
          marginTop: '68px'
        },
        m: {
          marginTop: '48px'
        }
      },
      style: {
        d: {
          marginTop: '21px',
          marginBottom: '102px'
        },
        m: {
          marginTop: '13px',
          marginBottom: '52px'
        }
      }
    }
  ];
  return (

    <MultiSectionPage sections={sections}>

      <Mobile>
        <Header size="28" style={{
          lineHeight: '43px',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}>
          Get Involved
        </Header>
      </Mobile>
      <Img
        style={{ width: '100%', background: '#f6f6f6' }}
        d={{
          height: '595px',
          marginTop: '10px'
        }}
        m={{
          height: '445px',
          marginTop: '17px'
        }}
      />
      <Desktop>

        <Header size="28" style={{
          lineHeight: '43px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginTop: '33.68px'
        }}>
          Get Involved
        </Header>
      </Desktop>
      <Div d={{
        marginTop: '34px',
        fontSize: '28px'
      }}
        m={{
          marginTop: '24px',
          marginBottom: '26px',
          fontSize: '20px'
        }}>
        <p>CiTR & Discorder couldn’t be made possible without the continuous support of all our fantastic volunteers.</p>
        <p><b>Interested in getting involved? </b></p>
        <p>Keep reading to find out how!</p>
        <Div size="28/16">More of a visual learner? Check out this flow chart walkthrough of how to get involved
          <Icon
            ChevronRight
            style={{
              height: Desktop() ? '18px' : '9px',
              paddingBottom: '3px',
              paddingLeft: '3px'
            }} />
        </Div>
      </Div>
    </MultiSectionPage>
  )
}