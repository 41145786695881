

import { Link } from 'react-router-dom';

import { DesktopClass } from '../modules/useResponsive';
import RouteFor from '../modules/RouteFor';
import { Div } from './Elements';

export default function IssueCard({ issue }) {
  return (
    <div className={DesktopClass("Tile issue ")} >
      <Link
        to={RouteFor({ issue: issue })}>
        <img style={{
          marginTop: '22px',
          width: '100%'
        }}
          className='cover'
          src={issue.image || '/images/fallback.jpg'}
          alt={issue.name}
        />
        <Div
          size='16/13'
          style={{
            marginTop: '3px'
          }}>
          {issue.date}
        </Div>
      </Link>
    </div>
  );
}