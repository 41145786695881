import './Read.scss';
import Data from '../modules/Data';
import Grid from '../components/Grid';
import Icon from '../components/Icon';
import { Desktop, DesktopClass } from '../modules/useResponsive';
import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Div } from '../components/Elements';
import RouteFor from '../modules/RouteFor';
import ColumnLink from '../components/ColumnLink';
import ArticleCard from '../components/ArticleCard';

export default function ReadColumn() {

  const { columns, selectedColumn } = useLoaderData();
  const [currentColumn, setCurrentColumn] = useState(selectedColumn);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setCurrentColumn(selectedColumn);//columns.find(c => c.id === columnId) );
    setExpanded(false);
  }, [selectedColumn]);

  function TheGrid() {
    return <Grid childElementFn={x => <ArticleCard article={x} key={x.id} />}
      loadFn={currentColumn ?
        (offset, pageSize) => Data.articles.byColumn(currentColumn.id, offset, pageSize) :
        () => Promise.resolve([])}
      style={{ marginTop: '0' }} />;
  }

  function toggleExpand() {
    setExpanded(!expanded);
  }

  function activeFirst(c1, c2) {
    if (!selectedColumn) {
      return 0;
    }
    if (c1.id === selectedColumn.id) {
      return -1;
    } else if (c2.id === selectedColumn.id) {
      return 1;
    } else {
      return 0;
    }
  }
  return (
    <div className={DesktopClass("ReadColumn")}>
      <h1 style={Desktop() ? {} : {}}>
        Browse By Column
      </h1>
      <Div
        className={"browse-by-column"}
        m={{
          marginTop: '24px',
          marginBottom: '24px'
        }}
        flex={Desktop() ? "row space-between center" : "column space-between flex-start"}
      >{
          Desktop() ? columns.map(column =>
            <ColumnLink
              to={RouteFor({ column })}
              key={column.id}
              name={column.name}
              className={column === currentColumn ? 'column' : 'column grey'}
            />)
            :
            columns.sort(activeFirst).map((column, i) =>
            (
              i === 0 ?
                <ColumnLink
                  name={column.name}
                  className={`column first-column ${expanded ? 'expanded' : ''}`}
                  flex="row space-between center"
                  onClick={toggleExpand} key="0" href="./"
                  style={expanded ? {} : { borderWidth: '0' }}
                >
                  {column.name} <Icon style={{
                    padding: '10px 7px 9px 7px',
                    ...{ transform: expanded ? 'rotate(0.5turn)' : '' }
                  }} ArrowDown />
                </ColumnLink>
                :
                <ColumnLink
                  to={RouteFor({ column })}

                  name={column.name}
                  className={'column grey'}
                  style={{ display: expanded ? 'block' : 'none' }}
                  key={column.id} />
            )
            )
        }</Div>
      <TheGrid />
    </div >
  );
}