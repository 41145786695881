
import { Div } from "./Elements"
import Icon from "./Icon"
const socialLinks = [
  {
    name: 'instagram',
    icon: <Icon Instagram></Icon>,
    url: "https://instagram.com/citr"
  },
  {
    name: 'facebook',
    icon: <Icon Facebook></Icon>,
    url: "https://facebook.com/citr"
  },
  {
    name: 'twitter',
    icon: <Icon Twitter></Icon>,
    url: "https://twitter.com/citr"
  },
  {
    name: 'youtube',
    icon: <Icon Youtube></Icon>,
    url: "https://youtube.com/citr"
  }
]


export default function CitrSocialLinks() {
  return (
    <Div flex="row flex-start center" style={{ gap: '8px' }}>
      {socialLinks.map(link => (
        <a href={link.url} target="_blank" rel="noreferrer" key={link.name}>
          {link.icon}
        </a>
      ))}
    </Div>
  )
}