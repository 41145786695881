
import { Desktop } from "./useResponsive";

export default function ParseStyleProps({ size, d, m, style, className, flex }) {

  var attrs = Desktop() ? (d ? d : {}) : (m ? m : {});
  var flexAttrs;// = {};
  var flexStyles = {};

  if (flex && flex !== true) {
    flexAttrs = flex.split(' ');
    if (flexAttrs.length > 0) {
      flexStyles = {
        display: 'flex',
        flexDirection: flexAttrs[0],
        justifyContent: flexAttrs[1],
        alignItems: (flexAttrs.length >= 3) ? flexAttrs[2] : null
      }
    }
  } else if (flex === true) {
    flexStyles = {
      display: 'flex'
    }
  }

  var classes = (className || "") + " " + (attrs.class || "");

  if (size) {
    var sizes = (size || "").split('/');
    if (sizes.length === 2) {
      size = Desktop() ? sizes[0] : sizes[1];
    }
    classes = appendClassName(classes, `size${size}`);
  }

  function appendClassName(orig, newClassName) {
    return (orig ? orig + " " + newClassName : newClassName);
  }


  return {
    style: {
      ...flexStyles,
      ...style,
      ...attrs
    },
    classes
  };
}