import { Link } from "react-router-dom";
import React from "react";
import { Desktop } from "./useResponsive";

var timeouts = {

};
export default function DebouncingHover({ children, link, className, style, onEnterFn, onLeaveFn, leaveTimeout, id }) {
  //console.log('new debouncing hover ', id);

  timeouts[id] = null;

  var tag = 'div';
  var props = {
    className,
    style: {
      ...style
    }
  };

  if (Desktop()) {
    props.onMouseEnter = () => {
      if (timeouts[id]) {
        clearTimeout(timeouts[id]);
        timeouts[id] = null;
        //        console.log("(enter) cleared timeout ");
      }
      //    console.log("(enter)")
      onEnterFn();
    };
    props.onMouseLeave = () => {
      // console.log("(leave) set time out");
      if (!timeouts[id]) {
        timeouts[id] = setTimeout(() => {
          //     console.log('calling onLeaveFn');
          onLeaveFn()
        }, leaveTimeout);
      }
    }
  }

  if (link && link.to) {
    tag = Link;
    props.key = link.key;
    props.to = link.to;
  }
  return React.createElement(tag, props, children);
}