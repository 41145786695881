
import { Header } from './Elements';
import { Desktop } from '../modules/useResponsive';
import Icon from './Icon';
import { Div, StyledLink } from './Elements';
import _ from 'lodash';


var albumLinkIcons = {
  bandcamp: <Icon Bandcamp alt="link to Bandcamp" />,
  spotify: <Icon Spotify alt="link to Spotify" />,
  soundcloud: <Icon SoundCloud alt="link to Soundcloud" />
}

function AlbumLinkIcons({ album, style }) {
  return (
    <Div
      flex
      d={{
        gap: '8px',
      }}
      m={{
        gap: '4px',
      }}
      style={style}>
      {album.links.map((link, i) =>
        <StyledLink
          to={link.url}
          target="_blank"
          rel="noreferrer"
          key={i}
          flex
          d={{
            width: '24px',
            height: '24px'
          }}
          m={{
            width: '13px',
            height: '13px'
          }}

        >
          {albumLinkIcons[link.name]}
        </StyledLink>
      )}
    </Div>
  );
}


function ContentChips({ style, album }) {
  var { isCancon, isLocal } = album;
  var wordColors = {
    cancon: '#FF342A',
    local: '#FFC601'
  }
  return _.compact([isCancon ? "cancon" : null, isLocal ? "local" : null]).map((word, i) => {

    return <Div key={i} size='16' style={
      {
        borderRadius: '12px',
        color: '#fff',
        background: wordColors[word],
        textTransform: 'uppercase',
        padding: '1px 6px 2px 6px',
        ...style
      }}
      m={{
        borderRadius: '9px',
        fontSize: '9px',
        padding:'0px 4px 0px 4px',
        lineHeight:'13px',
        height:'13px',
        ...style
      }}>{word}</Div>
  }

  );
}


export default function Album({ album, number, style, isNumberOne }) {

  function numberOneMobile() {
    return (
      <>
      <div key={number} style={{width:'calc(50% - 8px)', marginLeft: '16px', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
        <Header size='13'>
          {rankingText}
        </Header>

        <Header size='16' className='bold' style={
          {
            margin: 0
          }}>{album.title}</Header>
        <Header size='13' style={
          {
            height: '30px'
          }}>{album.artist}</Header>

        
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'end'}}>
      <AlbumLinkIcons album={album} />
          <Div flex="row" style={{gap:'8px'}}>
            <ContentChips album={album} />
          </Div>
      </div>
      </div>
      </>
    );
  }

  var numberOne = number === 1 && isNumberOne;
  var rankingText = "#" + `${100 + number}`.slice(1, 3);

  return Desktop() ? (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: numberOne ? '' : '24px',
        paddingBottom: numberOne ? '' : '24px',
        ...style
      }}>
        {!numberOne ? <img src={album.image} style={{ aspectRatio: '1', width: 'var(--column)' }} alt={album.name} /> : <></>}

        <div style={{
          marginLeft: numberOne ? '' : '16px',
          flexGrow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>


            <Div flex={"row start center"} style={{
              gap: '12px'
            }}>
              <Header size="20" className='bold' style={{paddingBottom:'2px'}}>
                {rankingText}
              </Header>
              <ContentChips album={album} />
            </Div>
            {numberOne ? <></> :
              <AlbumLinkIcons album={album} />}
          </div>
          <div>

            <Header className='bold' size='28' style={{ minHeight: '43px', marginTop: numberOne ? '12px' : '0' }} >{album.title}</Header>
            <Header
              size='20'
              style={{
              }}>
              {album.artist}
            </Header>
          </div>
          {numberOne ?
            <AlbumLinkIcons album={album} style={{ marginTop: '24px' }} /> : <></>}

        </div>
      </div>
    </>
  ) : (numberOne ? numberOneMobile() : 
  (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '8px',
        paddingBottom: '13px',
        ...style
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
          <Div flex="row space-between center" style={{
            width: '100%',
            height:'22px'
          }}>
            <Header size='13'> {rankingText} </Header>
            <Div flex="row" style={{ gap: '8px' }}>

              <ContentChips album={album} />
            </Div>
          </Div>

        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'stretch',
        }}>
          <div style={{ 
            width:"calc(var(--column) + var(--halfgutter))",
            height:"calc(var(--column) + var(--halfgutter))",

            }}>
            <img src={album.image} height='100%' alt={album.name} />
          </div>
          <div style={{
            flexGrow: 2,
            paddingLeft: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>

            <Header size='16' className='bold'>
              {album.title}
            </Header>
            <Header size='13'>
              {album.artist}
            </Header>
          </div>
          <AlbumLinkIcons album={album} />
        </div>
      </div>

    </>)
  );
}