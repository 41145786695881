import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";

import { unset } from "lodash";
import moment from "moment";
import { Div, Header, StyledLink } from "../components/Elements";
import RouteFor from "../modules/RouteFor";
import ShareButtons from "../components/ShareButtons";
import { DesktopClass } from "../modules/useResponsive";
import Data from "../modules/Data";
import ArticleCard from "../components/ArticleCard";

export default function ReadIssueArticle(params) {
  var { article } = useLoaderData();

  var [related, setRelated] = useState([]);
  var [next, setNext] = useState({});

  useEffect(() => {
    Data.articles.relatedTo(article.id).then(setRelated);
    Data.articles.next(article.date).then(setNext);
    return () => {
      setRelated([]);
      setNext({});
    };
  }, [article.id, article.date]);

  if (!article) {
    return (
      <Div className="error">
        Could not find the article you requested. Sorry about that.
      </Div>
    );
  }
  unset(article, "_links");
  //unset(article, '_embedded');
  /*  article.contributors = [
    "Written by Example Abc",
    "Illustration by Alice Example",
  ];*/

  var image = article.imageCropped || article.imageMedium || article.imageLarge;

  return (
    <div>
      <Div
        d={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "6em"
        }}
        m={{ marginBottom: "3em" }}
      >
        <Div size="16/13">
          {article.tags.length ? article.tags.join(", ") + " | " : ""}
          {moment(article.date).format("DD.MM.YY")}
        </Div>
        <Div size="48/28" html={article.title}></Div>

        <Div size="24/19" d={{ marginTop: "24px" }} m={{ marginTop: "19px" }}>
          {article.contributors.map((c) => (
            <div key={c}>{c}</div>
          ))}
        </Div>
        <Div d={{ marginTop: "24px" }} m={{ marginTop: "19px" }}>
          <ShareButtons route={RouteFor({ article })} />
        </Div>
        <Div d={{ marginTop: "24px" }} m={{ marginTop: "19px" }}>
          {image? <img
            style={{ width: "100%" }}
            src={article.imageCropped || "/images/fallback.jpg"}
            alt={article.imageAlt}
          /> : null }
        </Div>
        
        <div
          className={DesktopClass("article-html-content")}
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </Div>
      <pre>
        {
          //JSON.stringify(article, null, 2)
        }
      </pre>
      <Div
        flex
        d={{
          height: "597px",
          gap: "16px"
        }}
        m={{
          flexDirection: "column-reverse",
          gap: "56.88px"
        }}
        style={{
          gap: "16px"
        }}
      >
        <Div
          d={{
            minWidth: "50%"
          }}
        >
          <h1 size="16" className="bold">
            Related Articles
          </h1>
          <Div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              marginTop: "24px"
            }}
            d={{ gap: "16px" }}
            m={{ gap: "18px" }}
          >
            {related.map((relatedArticle) => (
              <ArticleCard article={relatedArticle} key={relatedArticle.id} />
            ))}
          </Div>
        </Div>
        <Div
          style={{
            flexGrow: "1"
          }}
        >
          <h1 size="16" className="bold">
            Next Story
          </h1>
          <Div
            flex="column center center"
            style={{
              width: "100%"
            }}
          >
            <Header
              size="48/20"
              d={{
                marginTop: "52px"
              }}
              m={{
                marginTop: "38.12px"
              }}
              style={{ textAlign: "center" }}
              html={next.title}
            ></Header>
            <Div
              size="28/16"
              d={{
                marginTop: "17px"
              }}
              m={{
                marginTop: "12px"
              }}
              style={{ textAlign: "center" }}
              html={next.description}
            ></Div>
            <StyledLink
              size="13"
              className="bold"
              to={RouteFor({ article: next })}
              d={{ marginTop: "34px" }}
              m={{ marginTop: "19px" }}
              style={{ textTransform: "uppercase" }}
            >
              Keep Reading
            </StyledLink>
          </Div>
        </Div>
      </Div>
    </div>
  );
}
