

import { Div } from "./Elements";
import Icon from "./Icon";

var shareFunctions = {
  facebook: link => {
    alert('share to facebook: ' + link);
  },
  twitter: link => {
    alert('share to twitter: ' + link);
  },
  link: link => {
    alert('copy this link: ' + link);
  }
}
export default function ShareButtons({ route }) {

  var link = 'https://citr.ca' + route;
  return (
    <Div className="socials" flex="row flex-start center">
      <Icon Facebook style={{ margin: "0.25em", cursor: 'pointer' }} onClick={() => shareFunctions.facebook(link)} />
      <Icon Twitter style={{ margin: "0.25em", cursor: 'pointer' }} onClick={() => shareFunctions.twitter(link)} />
      <Icon Link style={{ margin: "0.25em", cursor: 'pointer' }} onClick={() => shareFunctions.link(link)} />
    </Div>
  );


}