import { useEffect, useState } from "react";
import _ from 'lodash';
import { Div, Header, StyledLink } from "./Elements"
import { Desktop, DesktopClass, Mobile } from "../modules/useResponsive";
import ScrollBehaviour from "../modules/ScrollBehaviour";


function MultiSectionPage({ d, m, style, sections, children }) {
  // children gets injected at the top of the page


  var [activeLinkIndex, setActiveLinkIndex] = useState(0);


  function subNavigation() {

    return (
      <Div
        d={{
          textAlign: "right"
        }}>
        <Div
          size="16/13"
          d={{
            opacity: '0.5',
            paddingRight: '6px',
            lineHeight: '24px'
          }}
          m={{
            opacity: '0.6'
          }}
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }}>
          on this page
        </Div>
        <Div flex='column'
          d={{
            marginTop: '6px'
          }}
          m={{
            marginTop: '8px',
            gap: '4px'
          }}>

          {sections.map(({ name, id }, i) => {
            return (
              <StyledLink
                key={id + "-link"}
                size="20/16"
                style={{
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  opacity: '0.8',
                  display: 'block'
                }}
                d={{
                  background: i === activeLinkIndex ? 'var(--light-grey)' : ''
                }}
                m={{
                  background: 'var(--light-grey',
                  lineHeight: '30px',
                }}
                onClick={() => {
                  ScrollBehaviour.scrollToId(id);
                }
                }
              >
                {name}
              </StyledLink>);
          })
          }
        </Div>

      </Div>
    );
  }

  function updateNavScrollStyles() {
    _.each(sections, ({ id }, i) => {
      var rectangle = document.getElementById(id).getBoundingClientRect();
      var position = window.innerHeight;
      if (position > rectangle.top && position < rectangle.bottom) {
        setActiveLinkIndex(i);
        console.log(i);
      }
    });
  }

  useEffect(() => {
    // idea: debounce a bit for performance?
    window.addEventListener('scroll', updateNavScrollStyles)
    return () => {
      window.removeEventListener('scroll', updateNavScrollStyles)
    }
  }, []);

  return (
    <div>

      <Desktop>
        <Div style={{
          position: 'fixed',
          right: '0',
          top: '106px'
        }}>
          {subNavigation()}

        </Div>
      </Desktop>
      <Div className={DesktopClass("centre-column")}>

        {children}
        <Mobile>
          {subNavigation()}
        </Mobile>

        <Div d={d} m={m}>
          {sections.map(section => {
            return (
              <Div id={section.id} key={section.id}>
                <Header size="16"
                  className="bold top-border"
                  style={
                    {
                      textTransform: 'uppercase'
                    }
                  }
                  d={section.headerStyle.d}
                  m={section.headerStyle.m}
                >
                  {section.name}
                </Header>
                <Div
                  d={section.style.d}
                  m={section.style.m}

                  html={section.html} >
                  {section.children}
                </Div>
              </Div>
            )
          })}
        </Div>
      </Div>
    </div>
  )
};


export default MultiSectionPage