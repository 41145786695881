

import { Link } from 'react-router-dom';

import { Desktop } from '../modules/useResponsive';
import RouteFor from '../modules/RouteFor';

import _ from '../modules/_';
//import { Div } from './Elements';

import './Tile.scss';
import { Header } from './Elements';

import NanoClamp from 'nanoclamp';


export default function SearchResult({ show, episode, article, issue }) {

  var key = show ? "show" : (episode ? "episode" : (article ? "article" : (issue ? "issue" : null)));
  var data = show ? show : (episode ? episode : (article ? article : (issue ? issue : null)));

  if (episode) {
  data.image = _.get(episode, 'imageMedium') || _.get(episode, 'show.image') || '/images/fallback.jpg';
  } else {
    data.image = data.imageMedium || '/images/fallback.jpg'
  }
  if (key && data) {

    var to = {};
    to[key] = data;

    return (
      <Link
        to={RouteFor(to)}
      >
        <div style={{ display: "flex", gap: '16px', flexDirection: 'row' }}>
          <img src={data.image || '/images/fallback.jpg'} alt={data.name} style={Desktop() ? {
            height: '191.4px',
            width: '290px',
            objectFit: 'cover'
          } : {
            height: '135px',
            width: '108px',
            objectFit: 'cover'
          }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <div>
              <Header style={{
                textTransform: 'uppercase',
                fontSize: '13px',
                lineHeight: '16.9px',
                margin: 0,
                padding: 0,
                //border: '1px solid blue'
              }}>
                {key}
              </Header>
              <Header style={{
                fontSize: Desktop() ? '20px' : '16px',
                lineHeight: Desktop() ? '24px' : '20.8px',
                //border: '1px solid red',
                margin: 0,
                fontWeight: 'bold'
              }} html={data.title}>
              </Header>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: key === "article" ? 'row' : 'column',
              fontSize: Desktop() ? '16px' : '13px',
              lineHeight: Desktop() ? '19.2px' : '16.9px',
              //border: '1px solid green',
              gap: key === "article" ? '8px' : 0,
              textTransform: 'uppercase'
            }}
            >

              {data.tags && data.tags.length ? (
                <NanoClamp className="tags" text={`(${(data.tags.join(", "))})`} lines={2} ellipsis={'...'} accessibility={true} />)
                : <></>
              }
              <div> {data.formattedTime}</div>
            </div>
          </div>
        </div>
      </Link>);
  } else {
    return "empty search result";
  }


}