
//import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import React from 'react';
import ParseStyleProps from '../modules/ParseStyleProps';
import { Desktop, /*DesktopClass */ } from '../modules/useResponsive';
import { Button, Div } from './Elements';
import _ from 'lodash';
//import moment from 'moment';


export default function Grid({ id, childElementFn, initialData, loadFn, pageSize, buttonClass, buttonStyle, addLinesBetween, d, m, style, className, flex }) {

  style = ParseStyleProps({ d, m, style, className, flex })
    .style;

  
  pageSize = _.isNumber(pageSize) ? pageSize : (Desktop() ? 8 : 4);
  const [Items, setItems] = useState([..._.compact(initialData)]);

  id = id || 'grid '+ (className? className : '');
  var i;
  useEffect(() => {
    if (Items.length === 0) {
      loadAndAppend();
    }//eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if (_.isArray(initialData) && initialData.length > 0 && initialData !== Items)
    setItems([...initialData]);
  },[initialData])

  var [loading, setLoading] = useState(false);


  function loadAndAppend() {
    if (!loadFn) {
      return;
    }
    setLoading(true);
    i = 0;
    var offset = Items.length;
    Promise.resolve(loadFn(offset, pageSize))
      .then(i => {
        setItems([...Items, ...i]);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  }

  return (<>
    <Div id={id} style={{
      ...{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        marginTop: '24px'
      },
      ...style
    }}

      m={{
        gap: '18px'
      }}
    >{Items.length === 0 && !loading ? <div>(no items found)</div> : null}

      {addLinesBetween && Items.length > 0 ?
        Items.map(childElementFn).reduce((p, c) => [p, <hr key={`hr${i++}`} />, c])
        :
        Items.map(childElementFn)
      }
    </Div>

    <Div flex="row center" style={{
      width: '100%',
      margin: '34px 0px 24px 0px',
      ...buttonStyle
    }}>
      {loading ? "loading..." :
        Items.length? <Button
          className={buttonClass || ""}
          size='13/10'
          onClick={loadAndAppend}>
          Load More
        </Button> : null
      }
    </Div>
  </>);
}