import "./Read.scss";
import Data from "../modules/Data";
import Grid from "../components/Grid";
import ArticleCard from "../components/ArticleCard";
import Icon from "../components/Icon";
import { Div, Header, Img, StyledLink } from "../components/Elements";
import { Desktop, DesktopClass, Mobile } from "../modules/useResponsive";
//import { useEffect, useState } from 'react';
import { useLoaderData } from "react-router-dom";
import WhatWereReading from "../components/WhatWereReading";
import ColumnLink from "../components/ColumnLink";
import RouteFor from "../modules/RouteFor";

export default function Read() {
  const { latestIssue, columns } = useLoaderData();

  return (
    <div className={DesktopClass("Read")}>
      <div className={DesktopClass("Magazine")}>
        <div
          style={{
            display: "flex",
            alignItems: Desktop() ? "flex-end" : "flex-start",
            flexDirection: Desktop() ? "row" : "column",
            gap: "16px"
          }}
        >
          <div className={DesktopClass("latestIssueImageBox")}>
            <img
              style={{
                height: `calc(100% - ${Desktop() ? "48px" : "42.2px"})`
              }}
              className="latestIssueImage cover"
              src={latestIssue.imageLarge}
              alt="latest Discorder issue cover"
            />
          </div>
          <div>
            <Header size="28/20" className="bold">
              Latest Issue
            </Header>
            <Header size="28/20">{latestIssue.title}</Header>
            <Div
              size="20/16"
              m={{ marginTop: "24px" }}
              d={{ marginTop: "34px" }}
            >
              {latestIssue.description}
            </Div>

            <Div
              className="past-issues-links"
              flex="row space-between baseline"
              style={{
                marginTop: "48px",
                marginBottom: "78px",
                flexDirection: Desktop() ? "row" : "column"
              }}
              d={{
                marginBottom: "56px"
              }}
            >
              <StyledLink
                to={latestIssue.pdf}
                m={{ paddingBottom: "11.5px" }}
                target={"_blank"}
              >
                <Header size="16" className="upper bold">
                  Check it Out
                </Header>
              </StyledLink>
              <StyledLink
                to="/read/issues"
                flex="row center"
                className="grey upper bold"
                m={{
                  paddingTop: "11.5px",
                  paddingRight: "8px",
                  borderTop: "2px solid #F5F5F5"
                }}
              >
                View More Past Issues &nbsp;
                <Icon ArrowRight />
              </StyledLink>
            </Div>
          </div>
        </div>
      </div>

      <WhatWereReading />
      <h1
        style={{
          marginTop: Desktop() ? "52px" : "23px"
        }}
      >
        Browse By Column
      </h1>
      <Div
        className={"browse-by-column"}
        flex={
          Desktop()
            ? "row space-between center"
            : "column space-between flex-start"
        }
        m={{ paddingTop: "24px", paddingBottom: "36px" }}
      >
        {columns.map((column) => (
          <ColumnLink
            name={column.name}
            to={RouteFor({ column })}
            key={column.id}
          />
        ))}
      </Div>
      <h1 style={Desktop() ? { marginBottom: "24px" } : {}}>Latest Articles</h1>

      <Grid
        d={{ gap: "16px" }}
        m={{ gap: "18px" }}
        buttonStyle={
          Desktop()
            ? {
                marginTop: "52px"
              }
            : {
                marginTop: "0"
              }
        }
        childElementFn={(x) => <ArticleCard article={x} key={x.id} />}
        loadFn={Data.articles.latest}
        pageSize={Desktop() ? 8 : 4}
      ></Grid>
      <Div
        d={{
          marginTop: "74px",
          fontSize: "28px",
          lineHeight: "43px"
        }}
        m={{
          marginTop: "53px",
          fontSize: "20px",
          lineHeight: "30px"
        }}
        style={{
          overflow: "clip",
          border: "2px solid black",
          borderWidth: "2px 0px",
          marginLeft: "-100px",
          marginRight: "-100px",
          whiteSpace: "nowrap",
          width: "150%"
        }}
        className={"meets-edge"}
      >
        GET DISCORDER — GET DISCORDER — GET DISCORDER — GET DISCORDER — GET
        DISCORDER — GET DISCORDER — GET DISCORDER — GET DISCORDER — GET
        DISCORDER — GET DISCORDER — GET DISCORDER —
      </Div>
      <Div
        className={DesktopClass("centre-column")}
        d={{
          marginTop: "58px",
          fontSize: "48px",
          lineHeight: "57.6px"
        }}
        m={{
          marginTop: "24px",
          marginBottom: "52px",
          fontSize: "25px",
          lineHeight: "32.5px"
        }}
        style={{
          fontWeight: "bold",
          fontStyle: "italic",
          marginBottom: "100px"
        }}
      >
        <div>
          OUR SUBSCRIPTION RATES CAN’T BE BEAT! YOU PAY THE POSTAGE AND THAT’S
          IT!
        </div>

        <Img
          style={{
            float: "right"
          }}
          d={{
            width: "calc(66.6667% - 16px)"
          }}
          m={{
            width: "calc(83.3333% - 16px)",
            marginTop: "32px"
          }}
          src={"images/get-discorder.svg"}
        ></Img>
        <Div
          flex="flex row center"
          style={{
            float: "right"
          }}
          d={{
            marginBottom: "140px"
          }}
          m={{
            marginBottom: "52px",
            marginTop: "40px",
            height: "30px",
            fontSize: "20px"
          }}
        >
          <Desktop>GET IT WHILE IT'S HOT!</Desktop>
          <Mobile>
            GET IT WHILE IT'S HOT{" "}
            <Icon
              ArrowRight
              style={{
                height: "16px",
                marginLeft: "7px"
              }}
            />
          </Mobile>
        </Div>
      </Div>
    </div>
  );
}
