import { useMediaQuery } from 'react-responsive'

var minWidth = getComputedStyle(document.documentElement).getPropertyValue('--minWidthForDesktop');

var q = { minWidth };

export function Desktop(p) {
  var isDesktop = useMediaQuery(q);

  if (p && p.children) {
    return isDesktop ? p.children : null;
  } else {
    return isDesktop;
  }
}

export function Mobile(p) {
  var isDesktop = useMediaQuery(q);

  if (p && p.children) {
    return isDesktop ? null : p.children;
  } else {
    return !isDesktop;
  }
}

export function DesktopClass(x) {
  if (!x) x = "";
  if (Desktop()) {
    return `${x} Desktop`;
  } else {
    return x;
  }
}
