
import _ from "lodash";
import BigLink from "../components/BigLink";
import { Header } from "../components/Elements";
import MultiSectionPage from "../components/MultiSectionPage";

var moreInfoLinks = [
  {
    name: 'press',
    to: '../press'
  },
  {
    name: 'history',
    to: '../history'
  },
  {
    name: 'policy',
    to: '../policy'
  }
];

var infoSections = [
  {
    name: "About CiTR & Discorder",
    id: "about",
    html: _.range(0, 100).map(() => "about lorem ipsum for now").join('&nbsp;&nbsp;&nbsp;  ,   '),
    headerStyle: {},
    style: { d: { marginTop: '14px' }, m: { marginTop: '8px' } }
  },
  {
    name: "Board of Directors",
    id: "board",
    html: _.range(0, 100).map(() => "board lorem ipsum for now").join(' &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;  '),
    headerStyle: { d: { marginTop: '62px' }, m: { marginTop: '48px' } },
    style: { d: { marginTop: '12px' }, m: { marginTop: '24px' } }
  },
  {
    name: "More Info",
    id: "more-info",
    children: moreInfoLinks.map(({ name, to }) => {
      return <BigLink name={name} to={to} key={name} />
    }),
    headerStyle: {
      d: { marginTop: '136px', lineHeight: '72px', fontSize: '48px', fontWeight: 'normal', borderWidth: '0' },
      m: { marginTop: '104px', lineHeight: '43px', fontSize: '28px', fontWeight: 'normal', borderWidth: '0' },
    },
    style: {
      d: {
        marginTop: '48px',
        marginBottom: '96px'
      }, m: {
        marginTop: '34px',
        marginBottom: '68px'
      }
    }

  },
];


function Info() {

  return (

    <MultiSectionPage
      d={{
        marginTop: '36px'
      }}
      m={{
        marginTop: '25px'
      }}
      sections={infoSections}>
      <Header size="28"
        d={{
          marginTop: '34px'
        }}
        m={{
          marginBottom: '17px'
        }}
        style={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          lineHeight: '43px'
        }}>
        Info
      </Header>

    </MultiSectionPage>

  );

}

export default Info;