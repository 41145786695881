import { useState } from "react";
import Player from "./Player";
import AudioModule from "../modules/AudioModule";
import _ from '../modules/_';

export default function PlayerBar() {

  const [isVisible, setIsVisible] = useState(true);
  const [playable, setPlayable] = useState([]);
  const [loadMediaAndPlayOnNextRender, setLoadMediaAndPlayOnNextRender] = useState(false);
  
  AudioModule.provideVisibilityFnForPlayer((param) => {
    console.log("AudioModule.provideVisibilityFnForPlayer called :", param)
    if (param === false) {
      setIsVisible(false)
    } else if (param && param.episode && param.episode.showSlug) {

      //          console.log('show', show);
      var nowPlaying = { episode: param.episode };
      setPlayable(nowPlaying);
      setLoadMediaAndPlayOnNextRender(true);
      setIsVisible(true);
      // audioRef.current.addEventListener('loadeddata', play);
      //audioRef.current.load();
    } else if (
      _.isArray(param) &&
      _.isArray(playable) &&
      param.length > 0 &&
      playable.length > 0 &&
      JSON.stringify(param[0]) === JSON.stringify(playable[0])
    ) {
      console.log("do nothing, cuz it's already playing");
    } else {
      setPlayable(param);
      setLoadMediaAndPlayOnNextRender(true);
      setIsVisible(true);
    }
  });

//  AudioModule.updateOnAir();
  return (
    isVisible ? <Player
      playable={playable}
      setPlayable={setPlayable}
      loadMediaAndPlayOnNextRender={loadMediaAndPlayOnNextRender}
      setLoadMediaAndPlayOnNextRender={setLoadMediaAndPlayOnNextRender}
    /> : null
  );
}