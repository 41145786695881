const issues = [
  {
    id: "149",
    name: "Issue #149 - June 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "148",
    name: "Issue #148 - May 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "147",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "146",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "145",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "144",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "143",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "142",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "141",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "140",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "139",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
  {
    id: "138",
    name: "Issue #147 - April 2021",
    date: "June 2021",
    image: "https://via.placeholder.com/313x419",
  },
];

export default issues;
