import Data from "../modules/Data";
import ArticleCard from "./ArticleCard";
import { Desktop } from "../modules/useResponsive";
import { useEffect, useState } from "react";

import HorizontalScroller from "./HorizontalScroller";
import { Div } from "./Elements";

export default function WhatWereReading() {
  const [Items, setItems] = useState([]);

  useEffect(() => {
    if (Items.length === 0) {
      Data.articles.whatWereReading().then(setItems);
    }
  }, [Items.length]);

  function WhatWereReading() {
    return Items.map((a) => (
      <ArticleCard
        article={a}
        tall={true}
        key={a.id}
        className="keen-slider__slide"
      ></ArticleCard>
    ));
  }

  return (
    <>
      <h1>What we're reading</h1>
      {Desktop() ? (
        <Div
          flex="row space-between"
          style={{
            marginTop: "24px",
            marginBottom: "72px",
            padding: "1px"
          }}
        >
          {WhatWereReading()}
        </Div>
      ) : (
        <HorizontalScroller>{WhatWereReading()}</HorizontalScroller>
      )}
    </>
  );
}
